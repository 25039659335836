<template>
  <div class="column" :class="'item_' + classSuffix">
    <div
      class="stars"
      :class="{
        five_stars: data === 5,
        four_stars: data === 4,
        three_stars: data === 3,
        two_stars: data === 2,
        one_stars: data === 1,
      }"
    >
      <span>{{ strRating[data] }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "UkTableRowItemRating",
  props: ["data", "classSuffix"],
  data() {
    return {
      strRating: {
        1: "Ужасно!",
        2: "Плохо!",
        3: "Неплохо!",
        4: "Отлично!",
        5: "Супер!",
      },
    };
  },
};
</script>
