var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column",class:'item_' + _vm.classSuffix},[_c('div',{staticClass:"stars",class:{
      five_stars: _vm.data === 5,
      four_stars: _vm.data === 4,
      three_stars: _vm.data === 3,
      two_stars: _vm.data === 2,
      one_stars: _vm.data === 1,
    }},[_c('span',[_vm._v(_vm._s(_vm.strRating[_vm.data]))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }