<template>
  <div>
    <a-spin :spinning="loading">
      <div class="top">
        <div class="caption">{{ $route.name }}</div>
        <a-range-picker @change="onChangeRange" format="DD.MM.YYYY" />
      </div>
      <div class="middle">
        <div class="tabs">
          <ul>
            <li
              v-for="item in reviewsHeaderData"
              :key="item.name"
              :class="{ active: item.id === activeReviewsHeaderId }"
              @click="ratingFilterHandler(item)"
            >
              {{ item.name }}<span>{{ item.count }}</span>
            </li>
          </ul>
          <div class="full_reviews">
            <UkTable :columns="columns" :data="computedData" :itemsPerPage="10">
              <div class="uk-table-slot">Отзывов пока нет...</div>
            </UkTable>
          </div>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import moment from "moment";
import "moment/locale/ru";

import { bus } from "@/bus";

import UkTable from "@/components/4laps/UkTable";
import UkTableRowItemReview from "@/components/4laps/UkTableRowItemReview";
import UkTableRowItemRating from "@/components/4laps/UkTableRowItemRating";
import UkTableRowItemPhone from "@/components/4laps/UkTableRowItemPhone";
import UkTableRowItemEmail from "@/components/4laps/UkTableRowItemEmail";
import UkTableRowItemDate from "@/components/4laps/UkTableRowItemDate";
import UkTableRowItemRestaurant from "@/components/4laps/UkTableRowItemRestaurant";
import UkTableRowItemStatus from "@/components/4laps/UkTableRowItemStatus";

import { getResponses } from "@/helpers/dataHelpers";

const columns = [
  {
    name: "Отзыв",
    classSuffix: "review",
    id: "review",
    component: UkTableRowItemReview,
  },
  {
    name: "Рейтинг",
    classSuffix: "rating",
    id: "rating",
    component: UkTableRowItemRating,
    sorter: (a, b) => a - b,
  },
  {
    name: "Тел",
    classSuffix: "tel",
    id: "phone",
    component: UkTableRowItemPhone,
  },
  {
    name: "E-Mail",
    classSuffix: "email",
    id: "email",
    component: UkTableRowItemEmail,
  },
  {
    name: "Дата",
    classSuffix: "date",
    id: "date",
    component: UkTableRowItemDate,
    sorter: (a, b) => a - b,
  },
  {
    name: "Локация",
    classSuffix: "rest",
    id: "restaurant",
    component: UkTableRowItemRestaurant,
    filter: true,
  },
  {
    name: "Статус",
    classSuffix: "status",
    id: "status",
    component: UkTableRowItemStatus,
  },
];

const reviewsHeaderData = [
  {
    id: 0,
    name: "Все отзывы",
    count: 0,
    filter: [1, 2, 3, 4, 5],
  },
  {
    id: 1,
    name: "Положительные отзывы",
    count: 0,
    filter: [4, 5],
  },
  {
    id: 2,
    name: "Средние отзывы",
    count: 0,
    filter: [3],
  },
  {
    id: 3,
    name: "Негативные отзывы",
    count: 0,
    filter: [1, 2],
  },
];

function mappingB2F(ob) {
  return {
    id: ob.responseId,
    review: ob.text,
    rating: +ob.rating,
    phone: ob.phone,
    email: ob.email,
    date: moment.utc(ob.created_at, "YYYY-MM-DD[T]HH:mm:ss.SSSSSS[Z]").local(),
    restaurant: {
      key: ob.pointId,
      name: ob.pointName,
    },
    status: {
      status: ob.statusId,
      active: true,
    },
  };
}

export default {
  name: "Reviews",
  data() {
    return {
      data: [],
      columns,
      reviewsHeaderData,
      activeReviewsHeaderId: 0,
      dates: [],
      loading: true,
    };
  },
  methods: {
    ratingFilterHandler(item) {
      this.activeReviewsHeaderId = item.id;
    },
    onChangeRange(dates) {
      this.dates = dates;
    },
  },
  computed: {
    computedData: function () {
      const currentRatings =
        this.reviewsHeaderData[this.activeReviewsHeaderId].filter;

      let filteredData = this.data.filter((item) =>
        currentRatings.find((rating) => rating === item.rating)
      );

      if (this.dates.length === 2) {
        filteredData = filteredData.filter(
          (item) =>
            item.date >= this.dates[0].startOf("day") &&
            item.date <= this.dates[1].endOf("day")
        );
      }
      return filteredData;
    },
  },
  mounted: function () {
    getResponses(undefined, (response) => {
      this.data = response.data.data.map(mappingB2F);
      this.reviewsHeaderData.forEach((item) => {
        const filteredData = this.data.filter((itemData) =>
          item.filter.find((rating) => rating === itemData.rating)
        );
        item.count = filteredData.length;
      });
      this.loading = false;
    });
  },
  created() {
    bus.$on("Reviews update after save", (newData) => {
      const mappedData = mappingB2F(newData);
      console.log(
        "🚀 ~ file: Reviews.vue ~ line 197 ~ bus.$on ~ mappedData",
        mappedData
      );
      const item = this.data.find((item) => item.id === mappedData.id);
      console.log("🚀 ~ file: Reviews.vue ~ line 199 ~ bus.$on ~ item", item);
      if (item) Object.assign(item, mappedData);
      bus.$emit("UkTable stay at page");
    });
  },
  components: {
    UkTable,
  },
};
</script>

<style scoped>
.uk-table-slot {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.5);
}
</style>
